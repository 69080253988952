<template>
  <div class="battle-room">
    <CompHeader title="对战房间">
      <div class="comp-header-right-box">
        <div class="coin"></div>
      </div>
    </CompHeader>
    <div class="battle-room-header flex-b">
      <div class="left">房间ID：1234567890</div>
      <div class="right flex-s">
        <div class="box-num">
          <CompCoin imgName="" num="8" />
        </div>
        <div class="box-coin">
          <CompCoin num="11.04" />
        </div>
      </div>
    </div>
    <div class="battle-room-choose-box-list">
      <div class="scroll-box flex-s">
        <div v-for="item in 20" :key="item" class="item">
          <div class="img-box flex-c">
            <img
              class="w100"
              src="../../assets/newImages/demo.png"
              alt=""
              srcset="" />
          </div>
          <div class="coin">
            <CompCoin num="9.99" />
          </div>
        </div>
      </div>
    </div>
    <div class="battle-room-scroll-ing">
      <div class="float-title"></div>
      <div class="main-box flex-a">
        <div v-for="item in 4" :key="item" class="main-box-item">
          <div v-for="item in 20" :key="item" class="in-item-box"></div>
        </div>
      </div>
    </div>
    <div class="battle-room-reward-box-list">
      <div class="main-box flex-b">
        <div v-for="item in 4" :key="item" class="main-box-item">
          <div class="user-info-box">用户信息</div>
          <div class="battle-info">
            <div v-for="item in 20" :key="item" class="in-item-box">
              <div class="title">第一回合</div>
              <div class="goods-info">
                <div class="goods-title">久经沙场</div>
                <div class="goods-img flex-c">
                  <img
                    class="w100"
                    src="../../assets/newImages/demo.png"
                    alt=""
                    srcset="" />
                </div>
                <div class="goods-name">久经沙场</div>
                <div class="goods-coin">
                  <CompCoin num="9.9" />
                </div>
              </div>
            </div>
          </div>
          <!-- <div v-for="item in 20" :key="item" class="in-item-box"></div> -->
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style lang="scss">
.battle-room {
  background: #363022;
  min-height: 100vh;
  padding: 0.58rem 0rem 1.82rem;
  .battle-room-header {
    padding: 0.08rem 0.2rem;
    .left {
    }
    .right {
      .box-num {
        margin-right: 0.12rem;
      }
      .box-coin {
      }
    }
  }
  .battle-room-choose-box-list {
    width: 100%;
    height: 0.72rem;
    margin-bottom: 0.2rem;
    .scroll-box {
      width: 100%;
      height: 100%;
      overflow-x: auto;
      background: linear-gradient(180deg, #1d1300 0%, rgba(29, 19, 0, 0) 100%);
      .item {
        width: 0.72rem;
        height: 100%;
        padding: 0.08rem;
        display: flex;
        justify-content: center;
        flex-wrap: wrap;
        .img-box {
          width: 0.44rem;
          height: 0.44rem;
          margin-bottom: 0.03rem;
        }
        .coin {
        }
      }
    }
  }
  .battle-room-scroll-ing {
    width: 100%;
    height: 1.26rem;
    background: #eee;
    position: relative;
    .main-box {
      width: 100%;
      height: 100%;
      overflow: hidden;
      .main-box-item {
        width: 20%;
        height: 100%;
        overflow-y: auto;
        .in-item-box {
          width: 100%;
          height: 1rem;
          border: 1px solid red;
        }
      }
    }
    .float-title {
      width: 0.98rem;
      height: 0.18rem;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -50%);
      @include bgImg("ouhuang-title-bg");
    }
  }
  .battle-room-reward-box-list {
    padding: 0.12rem 0.12rem 0;
    .main-box {
      .main-box-item {
        width: 20%;
      }
    }
  }
}
</style>
